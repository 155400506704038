import React from "react"
import "../styles/globals.css"
import AppProviders from "../context"
import { ModalProvider } from "../context/ModalContext"
import { ToastContainer } from '../components/Toast/ToastContainer';
import Analytics from "../components/global/Analytics/Analytics"
import { useRouter } from "next/router";
import Head from "next/head";
import reportAccessibility from "../utils/reportAccessibility";

const VinousApp = ({ Component, pageProps }) => {
  const router = useRouter();
  const canonicalUrl = (`https://vinous.com` + (router.asPath === "/" ? "": router.asPath));

  return (
    <AppProviders>
      <Head>
        {canonicalUrl.includes("?") ? <link rel="canonical" href={canonicalUrl} /> : ""}
      </Head>
      <ModalProvider>
        <main>
          <Component {...pageProps} />
          <ToastContainer />
        </main>
      </ModalProvider>
      <Analytics />
    </AppProviders>
  )
}

reportAccessibility(React);

export default VinousApp;
