import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { ModalContext } from "../../../context/ModalContext";

const Modal = (props: {
  className?: string,
  width?: string,
  children?: any
}) => {

  const { modalContent, handleModal, modal } = React.useContext(ModalContext) as unknown as {
    modalContent: JSX.Element,
    handleModal: () => void,
    modal: boolean
  };
  const wrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [handleModal])

  const handleClick = (e) => {
    if(wrapper.current && wrapper.current === e.target && handleModal) {
      handleModal()
    }
  }

  if (modal) {
    return ReactDOM.createPortal(
      <div
        className={`modal-wrapper fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50 ${ props.className }`}
        ref={wrapper}
      >
        <div className={`bg-white m-4 relative rounded shadow-lg w-full md:w-3/4 lg:w-1/2 ${ props.width }`}>
          <div className="absolute p-2 -right-3 -top-3 bg-white rounded-full shadow-md cursor-pointer z-50" 
            onClick={handleModal}
            onKeyDown={(e) => e.key === "Enter" && handleModal()}
            role="button"
            tabIndex={0}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </div>
          <div className="relative overflow-hidden rounded max-h-90vh overflow-y-auto">
            { modalContent }
          </div>
          {/* <Button
            type="primary"
            label="Close Modal"
            onClick={handleModal}
          /> */}
        </div>
      </div>,
      document.getElementById("modal-root")
    )
  } else {
    return null;
  }
}

export default Modal;
